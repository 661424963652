import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#fff"};
  color: ${(p) => (p.color ? "#2E186A" : "#1A73E8")};
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 0px;
  padding: 5px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 120px;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid #1A73E8;
    background-color: #1A73E8;
  }
`;
