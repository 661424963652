// eslint-disable-next-line

import React from "react";
import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { Buttonstyle1 } from "../../common/Button1";
import { useHistory } from 'react-router-dom';
const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
  const history = useHistory();
//window.location.href="https://app2.paylesspara.fun/"
  const handleRedirect = () => {
    window.location.href="https://app2.paylesspara.fun/"
    }

    return (
      <>
        <CustomNavLinkSmall
         style={{ width: "120px" }}
        onClick={() => scrollTo("about")}>
          <Span>
          <Button >{t("About")}</Button>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
         style={{ width: "120px" }}
        onClick={() => scrollTo("mission")}>
          <Span>
          <Button>{t("Mission")}</Button>
           </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
         style={{ width: "120px" }}
        onClick={() => scrollTo("product")}>
          <Span>
          <Button>{t("Product")}</Button>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "120px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "120px" }}
          onClick={() => handleRedirect()}
        >
          <Span>
            <Buttonstyle1>{t("Account")}</Buttonstyle1>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
